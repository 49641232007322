<template>
  <div>
    <div class="we-text-p15 we-wrap-mg">
      <van-progress :percentage="100" />
    </div>
    <div>
      <div class="theight">辨识结果</div>
      <div class="we-text-p15">
        根据中华中医药学会标准，您的中医体质是湿热质、痰湿质、阳虚质、阴虚质、气虚质,有特禀质倾向。
      </div>
    </div>
    <div class="we-text-p">
      <van-button type="primary"  @click="$router.push({name:'analysisdetail'})" block>查看详情</van-button>
    </div>
  </div>
</template>

<script>
import {
 
  Progress,
  
  Button,
} from "vant";



export default {
  name: "questionresult",
  components: {
    [Progress.name]: Progress,
    [Button.name]: Button,
  },
  data() {
    //  this.lastFetchId = 0;
    // this.fetchUser = debounce(this.fetchUser, 800);
    return {
      title: "调查结果",
      answer: "",
      predisabel: true,
      subject, //问卷题目
      activeTab: 0,
      activeStem: 0,
      isEnd: false,
     
    };
  },
  computed: {},
  methods: {
    nextStem() {
      let lenght = this.subject[this.activeTab].list.length * 1 - 1;
      if (
        this.activeTab == this.subject.length - 1 &&
        this.activeStem == this.subject[this.activeTab].list.length * 1 - 1
      ) {
        this.isEnd = true;
      } else {
        if (this.activeStem < lenght) {
          this.activeStem++;
          this.predisabel = false;
        } else {
          this.activeTab++;
          this.activeStem = 0;
          this.title = this.subject[this.activeTab].title;
        }
      }
    },
    afterInsert() {
      this.getInfo();
    },
    showReport() {
      this.$refs.ReportItemView.show();
    },
  },
  mounted() {},
};
</script>
<style lang="less" scope>
.van-radio {
  height: 45px;
  line-height: 45px;
}
.stemButton {
  .van-button {
    width: 120px;
    text-align: center;
  }
}
</style>